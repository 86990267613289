import React, { useEffect, useState } from 'react';
import { Encounter } from '../hooks/apis';
import CollapsibleSection from './CollapsibleSection';
import KeyValuePair from './KeyValuePair';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isEmpty } from '../util/empty';

interface EncounterSummarySectionsProps {
  encounter?: Encounter;
  expandAll?: boolean;
}

const EncounterSummarySections: React.FC<EncounterSummarySectionsProps> = ({
  encounter,
  expandAll,
}) => {
  const encounterData = encounter?.data;

  // State to hold merged physical exam data
  const [mergedPhysicalExam, setMergedPhysicalExam] = useState(encounterData?.physicalExam || {});

  // State to hold merged review of systems data
  const [mergedReviewOfSystems, setMergedReviewOfSystems] = useState(encounterData?.reviewOfSystems || []);

  useEffect(() => {
    if (encounterData?.physicalExam) {
      // Merge new physical exam data with existing data
      setMergedPhysicalExam((prevData) => ({
        ...prevData,
        ...encounterData.physicalExam,
      }));
    }

    if (encounterData?.reviewOfSystems) {
      // Merge new review of systems data with existing data
      setMergedReviewOfSystems((prevData) => {
        const existingSystems = new Map(prevData.map(system => [system.system, system]));
        // Check if reviewOfSystems is defined and has elements
        if (encounterData.reviewOfSystems) {
          encounterData.reviewOfSystems.forEach(newSystem => {
            existingSystems.set(newSystem.system, newSystem);
          });
        }
        return Array.from(existingSystems.values());
      });
    }
  }, [encounterData]);

  const age = encounterData?.patient?.age ? `${encounterData?.patient?.age} ${encounterData?.patient?.ageUnit || ''}` : undefined;

  const renderFindings = (findings: any) => {
    if (!findings) return null;
    if (typeof findings === 'string') {
      return <div className="text-tesla-gray dark:text-tesla-dark-text/70">{findings}</div>;
    }
    return (
      <div className="flex">
        {findings.abnormal && (
          <span className="text-red-600 dark:text-red-400 mr-1">{findings.abnormal}</span>
        )}
        {findings.normal && (
          <span className="text-tesla-gray dark:text-tesla-dark-text/70">
            {findings.abnormal ? `. ${findings.normal}` : findings.normal}
          </span>
        )}
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderPhysicalExamFindings = (system: string, findings: any) => {
    if (!findings) return null;
    const capitalizedSystem = system.charAt(0).toUpperCase() + system.slice(1);

    // Handle other findings first
    if (system === 'other') {
      const otherFindings = findings.system;
      if (otherFindings && (otherFindings.normal || otherFindings.abnormal)) {
        return (
          <div key={system} className="mb-4">
            <h5 className="font-medium text-tesla-black dark:text-tesla-dark-text mb-1">{capitalizedSystem}</h5>
            <div className="pl-4">
              {otherFindings.abnormal && (
                <span className="text-red-600 dark:text-red-400">{otherFindings.abnormal}</span>
              )}
              {otherFindings.abnormal && otherFindings.normal && " . "}
              {otherFindings.normal && (
                <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                  {otherFindings.normal}
                </span>
              )}
            </div>
          </div>
        );
      }
    }

    // Handle nested structures like generalAssessment
    if (system === 'generalAssessment') {
      return (
        <div key={system} className="mb-4">
          <h5 className="font-medium text-tesla-black dark:text-tesla-dark-text mb-1">General Assessment</h5>
          <div className="pl-4">
            {findings.generalAppearance && (
              <div>
                {findings.generalAppearance.abnormal && (
                  <span className="text-red-600 dark:text-red-400">
                    {findings.generalAppearance.abnormal}
                  </span>
                )}
                {findings.generalAppearance.abnormal && findings.generalAppearance.normal && " . "}
                {findings.generalAppearance.normal && (
                  <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                    {findings.generalAppearance.normal}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }

    // Handle regular findings
    return (
      <div key={system} className="mb-4">
        <h5 className="font-medium text-tesla-black dark:text-tesla-dark-text mb-1">{capitalizedSystem}</h5>
        <div className="pl-4">
          {findings.abnormal && (
            <span className="text-red-600 dark:text-red-400">
              {findings.abnormal}
            </span>
          )}
          {findings.abnormal && findings.normal && " . "}
          {findings.normal && (
            <span className="text-tesla-gray dark:text-tesla-dark-text/70">
              {findings.normal}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CollapsibleSection
        title="Patient Demographics"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={age || encounterData?.patient?.name || encounterData?.patient?.sex}
      >
        <div className="space-y-2">
          <KeyValuePair label="Name" value={encounterData?.patient?.name} />
          <KeyValuePair label="Age" value={age} />
          <KeyValuePair label="Sex" value={encounterData?.patient?.sex} />
        </div>
      </CollapsibleSection>

     

      <CollapsibleSection
        title="Clinical Summary"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={{
          chiefComplaint: encounterData?.patient?.chiefComplaint,
          hpi: encounterData?.patient?.historyPresentIllness,
          ros: encounterData?.reviewOfSystems
        }}
      >
        <div className="space-y-4">
          <div>
            <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text mb-2">Chief Complaint</h4>
            <div className="text-tesla-gray dark:text-tesla-dark-text/70">
              {encounterData?.patient?.chiefComplaint}
            </div>
          </div>

          <div>
            <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text mb-2">History of Present Illness</h4>
            <div className="text-tesla-gray dark:text-tesla-dark-text/70">
              {encounterData?.patient?.historyPresentIllness}
            </div>
          </div>

          <div>
            <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text mb-2">Review of Systems</h4>
            <div className="space-y-2">
              {mergedReviewOfSystems.map((ros, index) => (
                <div key={index} className="mb-2">
                  <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text">{ros.system}</h4>
                  {renderFindings(ros)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Medical History"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={encounterData?.medicalHistory}
      >
        <div className="space-y-2">
          <KeyValuePair label="PMH" value={encounterData?.medicalHistory?.pmh} />
          <KeyValuePair label="PSH" value={encounterData?.medicalHistory?.psh} />
          <KeyValuePair label="FH" value={encounterData?.medicalHistory?.fh} />
          <KeyValuePair label="SH" value={encounterData?.medicalHistory?.sh} />
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Medications and Allergies"
        expandAll={expandAll}
        emptyCheck={{ medications: encounterData?.medications, allergies: encounterData?.allergies }}
      >
        <div className="space-y-2">
          <KeyValuePair label="Medications" value={encounterData?.medications} />
          <KeyValuePair label="Allergies" value={encounterData?.allergies} />
        </div>
      </CollapsibleSection>
      
      <CollapsibleSection
        title="Vitals"
        expandAll={expandAll}
        emptyCheck={encounterData?.vitals?.sets && encounterData.vitals.sets.length > 0}
      >
        <div className="pl-4">
          {/* Column Headers */}
          <div className="flex items-center mb-1">
            <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-20">Time</span>
            <div className="flex">
              <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-16">Temp</span>
              <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-16">HR</span>
              <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-16">BP</span>
              <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-16">RR</span>
              <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-16">O2</span>
            </div>
          </div>

          {/* Vitals Data */}
          <div className="space-y-2">
            {encounterData?.vitals?.sets?.map((set, index) => (
              <div key={index} className="flex items-center">
                {/* Timestamp */}
                <span className="text-tesla-gray dark:text-tesla-dark-text/70 w-20">
                  {set.timestamp}:
                </span>

                {/* Vitals with fixed widths */}
                <div className="flex">
                  <div className="w-16 flex items-center">
                    {set.temperature.value && (
                      <span className={set.temperature.abnormal ? "text-red-600 dark:text-red-400" : "text-black dark:text-tesla-dark-text"}>
                        {set.temperature.value}
                      </span>
                    )}
                  </div>
                  <div className="w-16 flex items-center">
                    {set.heartRate.value && (
                      <span className={set.heartRate.abnormal ? "text-red-600 dark:text-red-400" : "text-black dark:text-tesla-dark-text"}>
                        {set.heartRate.value}
                      </span>
                    )}
                  </div>
                  <div className="w-16 flex items-center">
                    {set.bloodPressure.value && (
                      <span className={set.bloodPressure.abnormal ? "text-red-600 dark:text-red-400" : "text-black dark:text-tesla-dark-text"}>
                        {set.bloodPressure.value}
                      </span>
                    )}
                  </div>
                  <div className="w-16 flex items-center">
                    {set.respiratoryRate.value && (
                      <span className={set.respiratoryRate.abnormal ? "text-red-600 dark:text-red-400" : "text-black dark:text-tesla-dark-text"}>
                        {set.respiratoryRate.value}
                      </span>
                    )}
                  </div>
                  <div className="w-16 flex items-center">
                    {set.oxygenSaturation.value && (
                      <span className={set.oxygenSaturation.abnormal ? "text-red-600 dark:text-red-400" : "text-black dark:text-tesla-dark-text"}>
                        {set.oxygenSaturation.value}%
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Physical Exam"
        expandAll={expandAll}
        emptyCheck={Object.keys(mergedPhysicalExam).length > 0}
      >
        <div className="space-y-2">
          {Object.entries(mergedPhysicalExam).map(([system, findings]) => {
            if (!findings || (typeof findings === 'object' && Object.keys(findings).length === 0)) {
              return null;
            }

            // Handle generalAssessment specially
            if (system === 'generalAssessment') {
              const generalAppearance = (findings as any).generalAppearance;
              if (!generalAppearance || (!generalAppearance.normal && !generalAppearance.abnormal)) return null;

              return (
                <div key={system} className="pl-4">
                  <span className="font-medium text-tesla-black dark:text-tesla-dark-text">
                    General Assessment:&nbsp;
                  </span>
                  {generalAppearance.abnormal && (
                    <span className="text-red-600 dark:text-red-400">
                      {generalAppearance.abnormal}
                    </span>
                  )}
                  {generalAppearance.abnormal && generalAppearance.normal && " . "}
                  {generalAppearance.normal && (
                    <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                      {generalAppearance.normal}
                    </span>
                  )}
                </div>
              );
            }

            // Handle HEENT findings
            if (system === 'heent') {
              return (
                <div key={system} className="pl-4">
                  <span className="font-medium text-tesla-black dark:text-tesla-dark-text">
                    HEENT Findings:&nbsp;
                  </span>
                  {findings.head && (findings.head.abnormal || findings.head.normal) && (
                    <div className="ml-4"> {/* Indent with margin-left */}
                      <span className="text-medium text-tesla-black dark:text-tesla-dark-text">
                        Head:
                      </span>
                      {findings.head.abnormal && (
                        <span className="text-red-600 dark:text-red-400">
                          {findings.head.abnormal}
                        </span>
                      )}
                      {findings.head.abnormal && findings.head.normal && " . "}
                      {findings.head.normal && (
                        <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                          {findings.head.normal}
                        </span>
                      )}
                    </div>
                  )}
                  {findings.ears && (findings.ears.abnormal || findings.ears.normal) && (
                    <div className="ml-4"> {/* Indent with margin-left */}
                      <span className="text-medium text-tesla-black dark:text-tesla-dark-text">
                        Ears:
                      </span>
                      {findings.ears.abnormal && (
                        <span className="text-red-600 dark:text-red-400">
                          {findings.ears.abnormal}
                        </span>
                      )}
                      {findings.ears.abnormal && findings.ears.normal && " . "}
                      {findings.ears.normal && (
                        <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                          {findings.ears.normal}
                        </span>
                      )}
                    </div>
                  )}
                  {findings.eyes && (findings.eyes.abnormal || findings.eyes.normal) && (
                    <div className="ml-4"> {/* Indent with margin-left */}
                      <span className="text-medium text-tesla-black dark:text-tesla-dark-text">
                        Eyes:
                      </span>
                      {findings.eyes.abnormal && (
                        <span className="text-red-600 dark:text-red-400">
                          {findings.eyes.abnormal}
                        </span>
                      )}
                      {findings.eyes.abnormal && findings.eyes.normal && " . "}
                      {findings.eyes.normal && (
                        <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                          {findings.eyes.normal}
                        </span>
                      )}
                    </div>
                  )}
                  {findings.nose && (findings.nose.abnormal || findings.nose.normal) && (
                    <div className="ml-4"> {/* Indent with margin-left */}
                      <span className="text-medium text-tesla-black dark:text-tesla-dark-text">
                        Nose:
                      </span>
                      {findings.nose.abnormal && (
                        <span className="text-red-600 dark:text-red-400">
                          {findings.nose.abnormal}
                        </span>
                      )}
                      {findings.nose.abnormal && findings.nose.normal && " . "}
                      {findings.nose.normal && (
                        <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                          {findings.nose.normal}
                        </span>
                      )}
                    </div>
                  )}
                  {findings.throat && (findings.throat.abnormal || findings.throat.normal) && (
                    <div className="ml-4"> {/* Indent with margin-left */}
                      <span className="text-medium text-tesla-black dark:text-tesla-dark-text">
                        Throat:
                      </span>
                      {findings.throat.abnormal && (
                        <span className="text-red-600 dark:text-red-400">
                          {findings.throat.abnormal}
                        </span>
                      )}
                      {findings.throat.abnormal && findings.throat.normal && " . "}
                      {findings.throat.normal && (
                        <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                          {findings.throat.normal}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              );
            }

            // Handle regular findings
            return (
              <div key={system} className="pl-4">
                <span className="font-medium text-tesla-black dark:text-tesla-dark-text">
                  {system.charAt(0).toUpperCase() + system.slice(1)}:&nbsp;
                </span>
                {(findings as any).abnormal && (
                  <span className="text-red-600 dark:text-red-400">
                    {(findings as any).abnormal}
                  </span>
                )}
                {(findings as any).abnormal && (findings as any).normal && " . "}
                {(findings as any).normal && (
                  <span className="text-tesla-gray dark:text-tesla-dark-text/70">
                    {(findings as any).normal}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="EKG Findings"
        expandAll={expandAll}
        emptyCheck={encounterData?.ekgFindings}
      >
        <div className="space-y-2">
          <KeyValuePair label="EKG Findings" value={encounterData?.ekgFindings} />
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Results"
        expandAll={expandAll}
        emptyCheck={{
          lab: encounterData?.labResults,
          imaging: encounterData?.imagingResults,
          tools: encounterData?.clinicalDecisionToolsResults
        }}
      >
        {/* Lab Results */}
        {encounterData?.labResults && encounterData.labResults.length > 0 && (
          <div className="mb-4">
            <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text mb-2">Lab Results</h4>
            <div className="space-y-2">
              {encounterData.labResults.map((result, index) => (
                <div key={index} className="mb-2">
                  <h5 className="font-medium text-tesla-black dark:text-tesla-dark-text">{result.name}</h5>
                  <div className="pl-4 space-y-1">
                    {result.values?.map((value, vIndex) => (
                      <div 
                        key={vIndex} 
                        className={value.abnormal ? "text-red-600 dark:text-red-400" : "text-tesla-gray dark:text-tesla-dark-text/70"}
                      >
                        <span className="inline-block w-40">{value.name}:</span>
                        <span>{value.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Imaging Results */}
        {encounterData?.imagingResults && encounterData.imagingResults.length > 0 && (
          <div className="mb-4">
            <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text mb-2">Imaging Results</h4>
            <div className="space-y-4">
              {encounterData.imagingResults.map((result, index) => (
                <div key={index} className="mb-4">
                  <h5 className="font-medium text-tesla-black dark:text-tesla-dark-text mb-2">{result.name}</h5>
                  {result.sections?.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="mb-2">
                      <h6 className="font-medium text-tesla-black dark:text-tesla-dark-text ml-4 mb-1">{section.title}</h6>
                      <div className="pl-8">
                        {section.abnormal && section.abnormal.length > 0 && section.abnormal.map((finding, findingIndex) => (
                          <div 
                            key={findingIndex} 
                            className="text-red-600 dark:text-red-400"
                          >
                            {finding}
                          </div>
                        ))}
                        {section.normal && (
                          <div className="text-tesla-gray dark:text-tesla-dark-text/70">
                            {section.normal}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Clinical Decision Tools Results */}
        {encounterData?.clinicalDecisionToolsResults && encounterData.clinicalDecisionToolsResults.length > 0 && (
          <div className="mb-4">
            <h4 className="font-semibold text-tesla-black dark:text-tesla-dark-text mb-2">
              Clinical Decision Tools Results
            </h4>
            <div className="space-y-2">
              {encounterData.clinicalDecisionToolsResults.map((result, index) => (
                <div key={index} className="mb-2">
                  <h5 className="font-medium text-tesla-black dark:text-tesla-dark-text">{result.name}</h5>
                  <div className="pl-4 space-y-1">
                    {result.values?.map((value, vIndex) => (
                      <div 
                        key={vIndex} 
                        className={value.abnormal ? "text-red-600 dark:text-red-400" : "text-tesla-gray dark:text-tesla-dark-text/70"}
                      >
                        <span className="inline-block w-40">{value.name}:</span>
                        <span>{value.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </CollapsibleSection>

    </>
  );
};

export default EncounterSummarySections; 