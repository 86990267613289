import React, { useState, useEffect } from 'react';
import { Mic, Send, Loader2, Loader } from 'lucide-react';
import { useEncounters, Encounter, useCreateEncounterApi } from '../hooks/apis';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { getRandomSymptom } from '../util/patientSymptoms';
import PatientCard from '../components/PatientCard';

const Home: React.FC = () => {
  const [prompt, setPrompt] = useState<string>('');
  const { fetchEncounters, data: encounters, isLoading, error } = useEncounters();
  const { createEncounterApi } = useCreateEncounterApi();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [displayedEncounters, setDisplayedEncounters] = useState<Encounter[]>([]);
  const [page, setPage] = useState<number>(1);
  const ENCOUNTERS_PER_PAGE = 30;

  useEffect(() => {
    fetchEncounters();
  }, [fetchEncounters]);

  useEffect(() => {
    if (encounters) {
      console.log('DEBUG Raw encounters data in Home:', encounters);
      setDisplayedEncounters(encounters.slice(0, page * ENCOUNTERS_PER_PAGE));
    }
  }, [encounters, page]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSubmitting(true);
    const { data, error } = await createEncounterApi({ 
      name: 'New Patient',
      description: ''
    });
    console.log('Created new encounter:', data);
    if (data) {
      navigate(`/encounter/${data.id}`, { state: { initialMessage: prompt } });
    }
    if (error) {
      console.error('Error creating encounter:', error);
      toast.error(`Error creating encounter: ${error}`);
    }
    setIsSubmitting(false);
  };

  const onStartRecording = async () => {
    const { data, error } = await createEncounterApi({ 
      name: 'New Patient',
      description: ''
    });
    if (data) {
      navigate(`/encounter/${data.id}`, { state: { startRecording: true } });
    }
    if (error) {
      console.error('Error creating encounter:', error);
      toast.error(`Error creating encounter: ${error}`);
      return;
    }
  };

  const handleRandomSymptom = async () => {
    const randomSymptom = getRandomSymptom();
    setPrompt(randomSymptom);
    const { data, error } = await createEncounterApi({ name: 'Patient Encounter', description: 'none' });
    if (data) {
      navigate(`/encounter/${data.id}`, { state: { initialMessage: randomSymptom } });
    }
    if (error) {
      console.error('Error creating encounter:', error);
      toast.error(`Error creating encounter: ${error}`);
    }
  };

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
  };

  return (
    <div className="min-h-screen font-sans bg-tesla-white dark:bg-tesla-dark-bg">
      <ToastContainer />
      <Navigation />
      <main className="max-w-7xl mx-auto mt-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 dark:text-tesla-dark-text mb-8 text-center">
          AI-Assisted Diagnostics
        </h1>

        <form onSubmit={handleSubmit} className="mb-8">
          <div className="flex items-start bg-white dark:bg-tesla-dark-surface rounded-lg shadow-md p-4">
            <button
              type="button"
              onClick={onStartRecording}
              className="p-2 bg-red-700 text-white rounded-full hover:bg-red-800 hover:border-2 hover:border-red-900 hover:shadow-[0_0_10px_rgba(185,28,28,0.5)] focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg mr-2 transition-all duration-200"
            >
              <Mic size={24} color="white" />
            </button>
            <textarea
              value={prompt}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPrompt(e.target.value)}
              placeholder="Enter patient symptoms or diagnostic query..."
              className="flex-grow p-4 rounded-lg focus:outline-none resize-none text-left bg-gray-50 dark:bg-tesla-dark-bg dark:text-tesla-dark-text dark:placeholder:text-tesla-dark-text/50 mx-2"
              rows={4}
              autoFocus
            />
            <div className="flex items-start space-x-2">
              <button
                type="submit"
                disabled={isSubmitting}
                className="p-2 bg-primary text-white rounded-full hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? <Loader2 size={24} className="animate-spin" /> : <Send size={24} />}
              </button>
              <button
                type="button"
                onClick={handleRandomSymptom}
                className="p-2 bg-secondary text-white rounded-full hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg"
                title="Random Symptom"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                  <circle cx="9" cy="7" r="4" />
                  <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>
              </button>
            </div>
          </div>
        </form>

        <div className="bg-white dark:bg-tesla-dark-surface rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-tesla-dark-text mb-4">
            Previous Patient Sessions
          </h2>
          {isLoading ? (
            <div className="flex justify-center items-center p-4">
              <Loader size={24} className="animate-spin text-gray-500 dark:text-tesla-dark-text/50" />
            </div>
          ) : error ? (
            <p className="text-red-500 dark:text-red-400">Error loading encounters: {error}</p>
          ) : displayedEncounters && displayedEncounters.length > 0 ? (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {displayedEncounters.map((encounter: Encounter) => (
                  <PatientCard key={encounter.id} encounter={encounter} />
                ))}
              </div>
              {encounters && displayedEncounters.length < encounters.length && (
                <div className="mt-6 text-center">
                  <button
                    onClick={handleLoadMore}
                    className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 dark:focus:ring-offset-tesla-dark-bg"
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <p className="text-gray-500 dark:text-tesla-dark-text/70">No previous encounters found.</p>
          )}
        </div>
      </main>
    </div>
  );
};

export default Home;
